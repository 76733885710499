import { walletStore } from '@/stores/wallet-store'
import Web3 from 'web3'
import { blockchainHandler } from '@/blockchainHandlers'

const web3 = blockchainHandler.getWeb3(process.env.VUE_APP_CHAIN_ID)!

async function sendRequest(fx, from) {
  return await new Promise((resolve, reject) => {
    fx.send({ from })
      .on('receipt', (result) => resolve(result))
      .on('error', (error) => reject(error))
  })
}

export class EmpirelandHeroNftHandler {
  nftContract: any

  constructor() {
    this.nftContract = new web3.eth.Contract(
      require('./abis/empirelandHeroNFT.abi.json'),
      process.env.VUE_APP_HERO_NFT_ADDRESS
    )
  }

  injectMetamask(web3: Web3) {
    if (web3) {
      this.nftContract = new web3.eth.Contract(
        require('./abis/empirelandHeroNFT.abi.json'),
        process.env.VUE_APP_HERO_NFT_ADDRESS
      )
    }
  }

  async getHeroNftIds() {
    const balance = await this.nftContract.methods.balanceOf(walletStore.account).call()
    const promises: Promise<number>[] = []
    for (let i = 0; i < balance; i++) {
      const tokenId = this.nftContract.methods.tokenOfOwnerByIndex(walletStore.account, i).call()
      promises.push(tokenId)
    }
    return Promise.all(promises)
  }

  async approveContract() {
    const LOCK_POOL_CONTRACT = process.env.VUE_APP_LOCK_POOL_CONTRACT
    const methods = this.nftContract.methods
    const account = walletStore.account
    const isApproved = await methods.isApprovedForAll(account, LOCK_POOL_CONTRACT).call()
    if (!isApproved) await sendRequest(methods.setApprovalForAll(LOCK_POOL_CONTRACT, true), account)
  }
}
